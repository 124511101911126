<template>
  <div class="Links part">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 text">
          <h4 class="mb-6" v-html="payload.titel" />
          <div class="links">
            <xstanding-link
              v-for="link in payload.links"
              :key="link.id"
              :to="link.link"
              class="link"
            >
              {{ link.text }}
            </xstanding-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    return {
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

:deep(a) {
  @extend .li2;
}

.link {
  @extend .li1;
  font-weight: 500;
}

.links {
  display: flex;
  flex-flow: column;
  row-gap: .3rem;
}

.Text.colored {
  color: var(--color-area);
}
</style>
